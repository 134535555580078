import {
  ErrorMessages,
  Info,
  OrderJourneyContent,
  Button,
} from '@sh24/ui-components'
import styled from 'styled-components'
import Basket from '../Basket/basket'
import Payment from '../Payment/payment'
import getQuestionInfo from '../../utils/get-question-info'
import useTranslations from '../../utils/use-translations'

const Header = styled.div`
  background-color: ${({ theme }) => theme?.palette?.white};
`

const GrandTotal = styled.div`
  border-top: 1px solid ${({ theme }) => theme?.components?.basket?.divideColor};
  text-align: right;
`

const Container = styled.div`
  height: 100%;
`

const Addresses = styled.div`
  gap: ${({ theme }) => theme?.spacing?.md};
`

const AddressRow = styled.span`
  display: block;
`

const addressFields = [
  'addressLine1',
  'addressLine2',
  'city',
  'postcode',
]

const Checkout = ({
  question,
  context,
  contextKey,
  dispatch,
  nextPage,
  prevPage,
}) => {
  const translations = useTranslations()
  const { totals } = context
  const errors = context?.errors?.[contextKey] || []
  const { infoTop, infoBottom } = getQuestionInfo(context, question)
  const basketTotal = totals?.basketTotal?.amount
  const grandTotal = totals?.grandTotal?.amount
  const basketHasValue = basketTotal > 0
  const somethingToPay = grandTotal > 0
  const billingAddress = context.billingAddressSame === 'yes'
    ? context.deliveryAddress
    : context.billingAddress

  const renderAddress = (key, address) => (
    <div>
      <h3 className="mb-md body body-base semi-bold">
        {translations[`orderJourney.checkout.${key}`]}
      </h3>
      {addressFields.map((field) => (
        <AddressRow key={`${key}-${field}`}>{address?.[field]}</AddressRow>
      ))}

      <Button
        variation="text"
        small
        text={translations.edit}
        onClick={prevPage}
      />
    </div>
  )

  const renderDeliveryAddress = () => {
    if (context?.addressConfirm && context?.addressConfirm === 'yes') {
      return renderAddress('lastKnownAddress', context.lastKnownAddress)
    }

    return renderAddress('deliveryAddress', context.deliveryAddress)
  }

  return (
    <Container>
      <Header>
        <OrderJourneyContent>
          <div>
            <h2 className="heading heading-3">{question.title}</h2>
            {(infoTop || infoBottom) && (
              <Info>
                {infoTop}
                {infoBottom}
              </Info>
            )}

            <div className="row mt-lg mb-lg">
              <Addresses className="col col-12 col-md-10 col-lg-9">
                {renderDeliveryAddress()}
                {billingAddress && (renderAddress('billingAddress', billingAddress))}
              </Addresses>
            </div>
          </div>
        </OrderJourneyContent>
      </Header>

      <OrderJourneyContent>
        <div className="row mt-md">
          <div className="col col-12">
            <h3 className="body body-base semi-bold">{translations['orderJourney.checkout.orderSummary']}</h3>
          </div>
        </div>
      </OrderJourneyContent>

      <Basket
        context={context}
        dispatch={dispatch}
      />

      <OrderJourneyContent>
        <div>
          {basketHasValue && (
            <div className="row">
              <div className="col col-12 col-md-10 col-lg-9">
                <GrandTotal className="pt-base pb-base">
                  <span className="mr-xl semi-bold">{translations[totals?.grandTotal?.translationKey]}</span>
                  <span className="heading heading-2">{totals?.grandTotal?.formattedAmount}</span>
                </GrandTotal>
              </div>
            </div>
          )}

          <div aria-live="polite">
            {errors?.length > 0 && (
              <div className="row">
                <div className="col col-12 col-md-10 col-lg-9">
                  <div className="mt-md mb-md">
                    <ErrorMessages id={contextKey} errors={errors} />
                  </div>
                </div>
              </div>
            )}
          </div>

          {somethingToPay && (
            <Payment
              title={translations['orderJourney.paymentMethod']}
              info={translations['orderJourney.paymentMethodInfo']}
              amount={grandTotal}
              context={context}
              dispatch={dispatch}
              nextPage={nextPage}
              question={question}
            />
          )}
        </div>
      </OrderJourneyContent>
    </Container>
  )
}

export default Checkout
