import fettle from './fettle'
import sh24 from './sh24'
import hcrg from './hcrg'
import sh24Ireland from './sh24_ireland'
import hepCIreland from './hep_c_ireland'
import bnssg from './bnssg'

export default {
  fettle,
  sh24,
  hcrg,
  sh24_ireland: sh24Ireland,
  hep_c_ireland: hepCIreland,
  bnssg,
}
