import { useEffect } from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { CheckboxField, OrderJourneyContent } from '@sh24/ui-components'
import getQuestionInfo from '../../utils/get-question-info'
import questionOptionOptions from '../../utils/document-to-react-component-renderers/question-option'

const CheckboxQuestion = ({
  question,
  contextKey,
  context,
  dispatch,
}) => {
  const checkedOptions = context[contextKey] || []
  const errors = context.errors?.[contextKey] || []
  const { infoTop, infoBottom } = getQuestionInfo(context, question)
  const options = question.options?.map((opt) => ({
    ...opt,
    details: opt.details ? documentToReactComponents(opt.details, questionOptionOptions) : null,
  })) || null

  useEffect(() => {
    if (!context[contextKey]) {
      dispatch(contextKey, checkedOptions)
    }
  }, [])

  const handler = (e) => {
    const newCheckedOpts = checkedOptions.filter((opt) => opt !== e.target.value)
    if (e.target.checked) newCheckedOpts.push(e.target.value)
    dispatch(contextKey, newCheckedOpts)
  }

  return (
    <OrderJourneyContent>
      <CheckboxField
        id={question.id}
        title={question.title}
        label={question.label}
        infoTop={infoTop}
        infoBottom={infoBottom}
        checkedOptions={checkedOptions}
        options={options}
        onChange={handler}
        errors={errors}
        {...question.props}
      />
    </OrderJourneyContent>
  )
}

export default CheckboxQuestion
