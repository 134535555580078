import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { RadioField, OrderJourneyContent } from '@sh24/ui-components'
import getQuestionInfo from '../../utils/get-question-info'
import questionOptionOptions from '../../utils/document-to-react-component-renderers/question-option'

const RadioQuestion = ({
  question,
  contextKey,
  context,
  dispatch,
}) => {
  const selected = context[contextKey]
  const errors = context?.errors?.[contextKey] || []
  const { infoTop, infoBottom } = getQuestionInfo(context, question)
  const options = question.options?.map((opt) => ({
    ...opt,
    details: opt.details ? documentToReactComponents(opt.details, questionOptionOptions) : null,
  })) || null
  const handler = (e) => {
    dispatch(contextKey, e.target.value)
  }

  return (
    <>
      <OrderJourneyContent>
        <RadioField
          id={question.id}
          title={question.title}
          label={question.label}
          infoTop={infoTop}
          infoBottom={infoBottom}
          selected={selected}
          options={options}
          onChange={handler}
          errors={errors}
        />
      </OrderJourneyContent>
    </>
  )
}

export default RadioQuestion
