import { OrderJourneyContent, RadioField } from '@sh24/ui-components'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import questionOptionOptions from '../../utils/document-to-react-component-renderers/question-option'

const AddressConfirm = ({
  question,
  contextKey,
  context,
  dispatch,
}) => {
  const lastKnownAddress = context?.lastKnownAddress
  const selected = context[contextKey]
  const errors = context?.errors?.[contextKey] || []
  const options = question.options?.map((opt) => ({
    ...opt,
    details: opt.details ? documentToReactComponents(opt.details, questionOptionOptions) : null,
  })) || null
  const handler = (e) => {
    dispatch(contextKey, e.target.value)
  }

  const renderLastKnownAddress = lastKnownAddress && (
    <div data-testid="lastKnownAddressDiv">
      {lastKnownAddress.addressLine1}<br />
      {lastKnownAddress.addressLine2 && <>{lastKnownAddress.addressLine2}<br /></>}
      {lastKnownAddress.addressLine3 && <>{lastKnownAddress.addressLine3}<br /></>}
      {lastKnownAddress.city}<br />
      {lastKnownAddress.postcode}<br />
    </div>
  )

  return (
    <OrderJourneyContent>
      <RadioField
        id={question.id}
        title={question.title}
        infoTop={renderLastKnownAddress}
        selected={selected}
        options={options}
        onChange={handler}
        errors={errors}
      />
    </OrderJourneyContent>
  )
}

export default AddressConfirm
