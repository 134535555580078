import { useState } from 'react'
import styled from 'styled-components'
import Step from './step'
import Service from './service'
import Header from './header'
import Conditions from '../preview/conditions'

const SectionDetails = styled.div`
    ${({ theme }) => `
    border: 2px solid ${theme.palette.black200};
    padding: ${theme.spacing.base};
    margin-bottom: ${theme.spacing.base};
    background-color: ${theme.palette.primary200};
  `}
`

const Section = ({ section, steps }) => {
  const [showSteps, setShowSteps] = useState(true)
  const toggle = () => setShowSteps(!showSteps)

  return (
    <>
      <SectionDetails>
        <Header text={section.title} callback={toggle} />
        {section.conditions && (
        <div className="mt-base">
          <Conditions conditions={section.conditions} />
        </div>
        )}
      </SectionDetails>
      {showSteps && steps.map((step) => (
        step.service
          ? <Service key={step.id} service={step} />
          : <Step key={step.id} step={step} />
      ))}
    </>
  )
}

export default Section
