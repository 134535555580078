import { camelize } from '../../../utils/helpers'

const getLastKnownAddress = async () => {
  const result = await fetch('/api/v2/account/last-known-address')

  const parsed = await result.json()

  if (result.status !== 200) return Promise.reject(parsed)

  const lastKnownAddress = parsed.address

  const sanitizedLastKnownAddress = {}

  if (lastKnownAddress) {
    Object.keys(lastKnownAddress).forEach((key) => {
      sanitizedLastKnownAddress[camelize(key)] = lastKnownAddress[key]
    })
  }

  return {
    context: {
      region: sanitizedLastKnownAddress.region,
      lastKnownAddress: sanitizedLastKnownAddress,
    },
  }
}

export default getLastKnownAddress
