import {
  getJourneySlugs,
  getJourney,
  getTranslations,
  getSiteConfig,
} from '@sh24/contentful-service'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { previewClient, contentDeliveryClient } from '../../../services/contentful'
import showDebugPanel from '../../../order-journeys/_v2/helpers/show-debug-panel'
import OrderJourneyLayout from '../../../layouts/order-journey'
import useDiscountSettings from '../../../utils/use-discount-settings'
import { JourneyContextProvider } from '../../../order-journeys/_v2/context/journey-context'
import OrderJourneyStepTemplateV2 from '../../../templates/order-journey-step-template.v2'
import OrderJourneyPreviewTemplate from '../../../templates/order-journey-preview-template'
import doStaticGeneration from '../../../utils/do-static-generation'
import PreviewModeToggle from '../../../components/OrderJourneyPreview/preview-mode-toggle'

const StepPage = ({ journey, showDebug }) => {
  const discountSettings = useDiscountSettings()
  const { isPreview } = useRouter()
  const [view, setView] = useState('journey')

  const views = {
    journey: <OrderJourneyStepTemplateV2 showDebug={showDebug} />,
    summary: <OrderJourneyPreviewTemplate journey={journey} />,
  }

  return (
    <JourneyContextProvider journey={journey} discountSettings={discountSettings}>
      {isPreview && <PreviewModeToggle view={view} setView={setView} />}
      {views[view]}
    </JourneyContextProvider>
  )
}

StepPage.Layout = OrderJourneyLayout

export async function getStaticPaths() {
  let paths = []

  if (doStaticGeneration()) {
    const client = contentDeliveryClient
    const slugs = await getJourneySlugs({ client })
    paths = slugs.map(([slug]) => ({ params: { slug, step: 'new' } }))
  }

  return { paths, fallback: 'blocking' }
}

export async function getStaticProps({ preview, params }) {
  const client = preview ? previewClient : contentDeliveryClient
  const { slug } = params
  const journey = await getJourney({
    client,
    slug,
    include: 7,
  })
  const translations = await getTranslations({ client })
  const siteConfig = await getSiteConfig({ client })
  const showDebug = showDebugPanel()

  return {
    props: {
      key: journey?.id,
      journey,
      translations,
      siteConfig,
      showDebug,
      preview: !!preview,
    },
    revalidate: 600,
    notFound: !journey,
  }
}

export default StepPage
