const getAvailablePrepOrders = async () => {
  const result = await fetch('/api/v2/account/prep-subscription')

  const parsed = await result.json()

  if (result.status !== 200) return Promise.reject(parsed)

  const prepSubscription = (parsed.prepSubscription && parsed.prepSubscription.state === 'active') || false
  const stiAvailable = parsed.availableServices ? parsed.availableServices.stiTestOrder : false
  const prepAvailable = parsed.availableServices ? parsed.availableServices.prepOrder : false
  const repeatHivTestAvailable = parsed.availableServices ? parsed.availableServices.repeatHivTest : false

  return {
    context: {
      prepSubscription,
      stiAvailable,
      prepAvailable,
      repeatHivTestAvailable,
    },
  }
}

export default getAvailablePrepOrders
