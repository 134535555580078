import { useContext, useEffect } from 'react'
import {
  Button, Callout, RadioField, OrderJourneyContent,
} from '@sh24/ui-components'
import { signOut } from 'aws-amplify/auth'
import { useRouter } from 'next/router'
import useTranslations from '../../utils/use-translations'
import getQuestionInfo from '../../utils/get-question-info'
import SessionContext from '../../contexts/session-context'

export const OPTS = {
  GUEST: 'guest',
  LOGGED_IN: 'account_loggedin',
  LOGIN: 'account_login',
  SIGNUP: 'account_signup',
}

const AccountOptions = ({
  question,
  contextKey,
  context,
  dispatch,
  setCustomNextHandler,
}) => {
  const { user, setUser } = useContext(SessionContext)
  const translations = useTranslations()
  const loggedIn = !!user
  const selected = context[contextKey]
  const errors = context?.errors?.[contextKey] || []
  const { infoTop, infoBottom } = getQuestionInfo(context, question)
  const { push, asPath } = useRouter()

  const handler = (e) => {
    dispatch(contextKey, e.target.value)
  }

  const signInHandler = async (e) => {
    e?.preventDefault()

    const options = { prompt: 'login' }
    let pushPath = '/account/login'

    sessionStorage.setItem('redirectUrl', asPath)

    if (selected === OPTS.SIGNUP) {
      options.screen_hint = 'signup'
      pushPath = '/account/sign-up/email'
    }

    await push(pushPath)
  }

  const signOutHandler = async (e) => {
    e.preventDefault()
    await signOut()
    setUser?.(null)
  }

  useEffect(() => {
    dispatch(contextKey, loggedIn ? OPTS.LOGGED_IN : OPTS.SIGNUP)
  }, [loggedIn])

  useEffect(() => {
    const signInOnContinue = selected === OPTS.LOGIN || selected === OPTS.SIGNUP
    setCustomNextHandler(() => (signInOnContinue ? signInHandler : null))
    dispatch('serviceUserEmailVerified', selected === OPTS.LOGGED_IN ? user?.email_verified === 'true' : null)
    dispatch('serviceUserPhoneNumberVerified', selected === OPTS.LOGGED_IN ? user?.phone_number_verified === 'true' : null)
    dispatch('serviceUserFlow', selected === OPTS.LOGGED_IN ? 'account' : OPTS.GUEST)
    dispatch('username', selected === OPTS.LOGGED_IN ? user?.sub : null)
    return () => setCustomNextHandler(null)
  }, [selected])

  const loggedInOptions = [OPTS.GUEST, OPTS.LOGGED_IN]
  const loggedOutOptions = [OPTS.GUEST, OPTS.LOGIN, OPTS.SIGNUP]

  const options = question.options.filter((opt) => (
    loggedIn
      ? loggedInOptions.includes(opt.value)
      : loggedOutOptions.includes(opt.value)
  ))

  return (
    <>
      <OrderJourneyContent>
        <RadioField
          id={question.id}
          title={question.title}
          infoTop={infoTop}
          infoBottom={infoBottom}
          selected={selected}
          options={options}
          onChange={handler}
          errors={errors}
        />
      </OrderJourneyContent>

      {loggedIn && (
        <OrderJourneyContent>
          <Callout
            title={`${translations['accountCallout.greeting']} ${user?.email}`}
            backgroundColour="/tertiary100"
            iconBackgroundColour="/secondary200"
            icon="user"
            content={(
              <p>{translations['accountCallout.message']}</p>
            )}
            ctaButton={(
              <Button
                text={translations['accountCallout.signout']}
                variation="text"
                onClick={signOutHandler}
              />
            )}
          />
        </OrderJourneyContent>
      )}
    </>
  )
}

export default AccountOptions
