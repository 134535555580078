const homeCountries = {
  fettle: [
    {
      text: 'United Kingdom',
      value: 'GBR',
    },
  ],
  hep_c_ireland: [
    {
      text: 'Ireland',
      value: 'IRL',
    },
  ],
  sh24: [
    {
      text: 'United Kingdom',
      value: 'GBR',
    },
  ],
  hcrg: [
    {
      text: 'United Kingdom',
      value: 'GBR',
    },
  ],
  sh24_ireland: [
    {
      text: 'Ireland',
      value: 'IRL',
    },
  ],
  bnssg: [
    {
      text: 'United Kingdom',
      value: 'GBR',
    },
  ],
}

export default homeCountries
