const calculateShowContinueButton = ({
  hideContinueButton,
  isPaymentStep,
  hasBasketItems,
  totals,
}: {
  hideContinueButton: boolean
  isPaymentStep: boolean
  hasBasketItems: boolean
  totals?: { grandTotal?: { amount: number } }
}): boolean => {
  if (hideContinueButton) return false

  return isPaymentStep ? (totals?.grandTotal?.amount === 0) : !hasBasketItems
}

export default calculateShowContinueButton
