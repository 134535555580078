import { useState } from 'react'
import styled from 'styled-components'
import {
  OrderJourneyContent,
  ErrorMessages,
} from '@sh24/ui-components'
import ProductSelectionGrid from '../ProductSelectionGrid/product-selection-grid'
import getQuestionInfo from '../../utils/get-question-info'
import useTranslations from '../../utils/use-translations'

const ProductCount = styled.div`
  color: ${({ theme }) => theme?.palette?.black200};
`

const filters = {
  productSelectionExtras: (product) => (product.isExtra),
  productSelectionPrimary: (product) => (product.isPrimary),
  productSelectionSecondary: (product) => (product.isSecondary),
}

const ProductSelection = ({
  question,
  context,
  dispatch,
  setStatusText,
}) => {
  const translations = useTranslations()
  const [timer, setTimer] = useState(null)
  const products = context.products?.filter(filters[question.component]) || []
  const errors = context?.errors || {}
  const { infoTop, infoBottom } = getQuestionInfo(context, question)

  const handleStatusUpdate = (text) => {
    setStatusText(text)
    clearTimeout(timer)
    setTimer(setTimeout(() => {
      setStatusText(null)
    }, 5000))
  }

  return (
    <OrderJourneyContent wide>
      <div>
        <div className="mb-md">
          <h2>{question.title}</h2>
          {infoTop && (
          <div className="mt-base">
            {infoTop}
          </div>
          )}
        </div>
        {products.length > 1 && (
          <ProductCount className="mb-base body-sm">
            {products.length} {translations['orderJourney.products']}
          </ProductCount>
        )}

        <ProductSelectionGrid
          products={products}
          context={context}
          dispatch={dispatch}
          setStatusText={handleStatusUpdate}
        />

        {infoBottom && (
          <div className="mt-base">
            {infoBottom}
          </div>
        )}

        <ErrorMessages id="basket" errors={errors.basket} />
      </div>
    </OrderJourneyContent>
  )
}

export default ProductSelection
