import airbrake from '../../../services/exception-notifier/client'
import { getServiceName } from '../config'

/* eslint-disable camelcase */
const decrementQuota = async ({ quota_exempt, region_lookup, journey }) => {
  if (quota_exempt) return {}

  const serviceName = getServiceName({ slug: journey.slug })
  const data = JSON.stringify({
    regionLookup: region_lookup,
    serviceName,
  })

  const res = await fetch('/api/decrement-quota', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: data,
  })

  if (res.ok) return {}

  await airbrake.notify('decrementQuota failed')

  return {}
}

export default decrementQuota
